import styled from '@emotion/styled';
import { StyledAccordionProps } from '..';

const StyledAccordionB = styled.div<StyledAccordionProps>`
    ${({ theme, disabled, hideBorder, open }) => `
         ${hideBorder ? '' : open ? `border: 1px solid ${theme.gray[900]};` : `border: 1px solid ${theme.gray[200]};`}
        border-radius: 8px;
        background: ${disabled ? theme.gray['50'] : theme.white};
        .material-symbols-outlined{
            color:${!disabled && '#000'};
            font-family: 'Material Symbols rounded';
            font-size: 36px;
            margin-right: -12px;
        }
    `}
`;

export default StyledAccordionB;
