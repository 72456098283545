import styled from '@emotion/styled';
import { StyledAccordionProps } from '..';

const StyledAccordionC = styled.div<StyledAccordionProps>`
    ${({ theme, disabled, hideBorder, open, padding }) => `
         ${hideBorder ? '' : open ? `border: 1px solid ${theme.gray[900]};` : `border: 1px solid #e6e6e6;`}
        border-radius: 8px;
        background: ${disabled ? theme.gray['50'] : theme.white};
        padding: ${padding};

        & * {
            font-size: 15px !important;
        }

        .accordion-trigger > button {
            height: 44px;
        }

        .material-symbols-outlined {
            color:${!disabled && theme.gray[400]};
            font-family: 'Material Symbols rounded';
            font-size: 20px;
            margin-right: -12px;
        }
    `}
`;

export default StyledAccordionC;
