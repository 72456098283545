import StyledAccordion from '@legacy/designs/styled-accordion';
import styled from '@emotion/styled';
import { BlockAccordion, BlockAccordionContent, BlockAccordionItem, BlockAccordionTrigger, BlockAccordionTypeEnum, BlockArrowIconEnum } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { ReactNode, useEffect, useState } from 'react';
import BodyText from './text/body-text';

export interface DropdownAccordionProps {
    trigger: string | ReactNode;
    isPlaceholder?: boolean;
    hideBorder?: boolean;
    open?: boolean;
    onToggle?: (open: boolean) => void;
    contentMaxHeight?: string;
    disabled?: boolean;
    children?: ReactNode;
    padding?: string;
}

const ITEM_NAME = '0';

const DropdownAccordion: React.FC<DropdownAccordionProps> = ({ disabled, open, onToggle, hideBorder, trigger, contentMaxHeight, children, padding, isPlaceholder }) => {
    const { theme, designSettings } = useHarmony();
    const [openItem, setOpenItem] = useState<string[]>([]);

    useEffect(() => {
        if (open !== undefined) {
            setOpenItem(open ? [ITEM_NAME] : []);
        }
    }, [open]);

    return (
        <StyledAccordion theme={theme} hideBorder={hideBorder} disabled={disabled} open={open} padding={padding}>
            <BlockAccordion
                type={BlockAccordionTypeEnum.multiple}
                value={openItem}
                disabled={disabled}
                onValueChange={(value) => {
                    if (onToggle) {
                        onToggle(value.includes(ITEM_NAME));
                    } else {
                        setOpenItem(value);
                    }
                }}
            >
                <BlockAccordionItem value={ITEM_NAME}>
                    <TriggerContainer className="accordion-trigger">
                        <BlockAccordionTrigger arrowIcon={designSettings?.isDesignB ? BlockArrowIconEnum.triangle : BlockArrowIconEnum.line}>
                            <BodyText
                                size="14px"
                                color={designSettings?.isDesignB && isPlaceholder ? theme.gray[300] : theme.gray[800]}
                                lineClamp={1}
                                wordBreak="break-all"
                                ellipsis
                            >
                                {trigger}
                            </BodyText>
                        </BlockAccordionTrigger>
                    </TriggerContainer>
                    <BlockAccordionContent>
                        <TriggerContent maxHeight={contentMaxHeight}>{children}</TriggerContent>
                    </BlockAccordionContent>
                </BlockAccordionItem>
            </BlockAccordion>
        </StyledAccordion>
    );
};

export default DropdownAccordion;

const TriggerContainer = styled.div`
    padding: 0 16px;
`;

const TriggerContent = styled.ul<{ maxHeight?: string }>`
    max-height: ${({ maxHeight }) => maxHeight};
    overflow-y: scroll;
`;
