import { useDesignComponents } from '@hooks/use-design-components';

import { IThemeProps } from '@type/common';
import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';
import StyledAccordionA from './designA/styled-accordion';
import StyledAccordionB from './designB/styled-accordion';
import StyledAccordionC from './designC/styled-accordion';

export interface StyledAccordionProps extends IThemeProps {
    hideBorder?: boolean;
    disabled?: boolean;
    open?: boolean;
    children: ReactNode;
    padding?: string;
}

const StyledAccordion: React.FC<StyledAccordionProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof StyledAccordionA | typeof StyledAccordionB>({
        [Design.DesignA]: StyledAccordionA,
        [Design.DesignB]: StyledAccordionB,
        [Design.DesignC]: StyledAccordionC,
    });

    return <Component {...props} />;
};

export default StyledAccordion;
