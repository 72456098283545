import styled from '@emotion/styled';
import { StyledAccordionProps } from '..';

const StyledAccordionA = styled.div<StyledAccordionProps>`
    ${({ theme, hideBorder }) => `
        ${
            hideBorder
                ? ''
                : `
            border: solid 1px ${theme.gray[200]};
        `
        }
    `}
`;

export default StyledAccordionA;
